import { isJson } from "@/helpers/isJson";
import Head from "next/head";
import React from "react";

function MetaTags(props: {
  title: string;
  description?: string | null;
  keywords?: string | null;
  pageUrl: string;
  faviconSrc?: string | null;
  imageSrc?: string | null;
  noIndex?: boolean;
  openGraphTitle?: string | null;
  openGraphDescription?: string | null;
  openGraphImage?: string;
}) {
  const {
    title,
    description = "",
    keywords = "",
    pageUrl,
    faviconSrc = "",
    imageSrc = "",
    noIndex = false,
    openGraphTitle,
    openGraphDescription,
    openGraphImage,
  } = props;

  const logoUrl = isJson(imageSrc) ? JSON.parse(imageSrc)?.url : imageSrc;

  return (
    <Head>
      <link rel="canonical" href={`https://${pageUrl}`} />
      {/* <!-- Primary Meta Tags --> */}
      {title && (
        <>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="application-name" content={title} />
          <meta name="apple-mobile-web-app-title" content={title} />
        </>
      )}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords}></meta>}
      {!!faviconSrc && (
        <link rel="icon" type="image/x-icon" href={faviconSrc} />
      )}
      {!!faviconSrc && (
        <link rel="apple-touch-icon" type="image/x-icon" href={faviconSrc} />
      )}
      {noIndex ? (
        <meta name="robots" content="noindex, nofollow" />
      ) : (
        <meta name="robots" content="index, follow" />
      )}

      {/* <!-- Open Graph --> */}
      <meta property="og:type" content="website" />
      {pageUrl && <meta property="og:url" content={pageUrl} />}
      {openGraphTitle && <meta property="og:title" content={openGraphTitle} />}
      {openGraphDescription && (
        <meta property="og:description" content={openGraphDescription} />
      )}
      {(openGraphImage || logoUrl) && (
        <meta property="og:image" content={openGraphImage || logoUrl} />
      )}

      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      {pageUrl && <meta property="twitter:url" content={pageUrl} />}
      {openGraphTitle && (
        <meta property="twitter:title" content={openGraphTitle} />
      )}
      {openGraphDescription && (
        <meta property="twitter:description" content={openGraphDescription} />
      )}
      {(openGraphImage || logoUrl) && (
        <meta property="twitter:image" content={openGraphImage || logoUrl} />
      )}
    </Head>
  );
}

export default MetaTags;
