import React from "react";

function Logo() {
  return (
    <svg width="32" height="25" viewBox="0 0 32 25">
      <g fill="#FFF" fillRule="evenodd">
        <path d="M7.303.116C7.965-.08 8.852-.006 9.326.18a.91.91 0 0 1 .437.316c.132.186.18.433.146.742-.062.54-.75 1.995-1.446 3.507-1.023 2.22-1.252 2.653-2.05 4.892-.797 2.24-1.39 4.284-1.778 6.13-.389 1.846-.542 3.35-.46 4.511.039.537.367 1.542 1.043 1.742.68.202 1.65-.474 2.33-1.742l-.034.058 1.433 2.384C7.841 24.3 6.148 25.16 4.612 24.918l-.191-.037c-1.245-.274-2.047-1.224-2.085-1.275-.893-1.17-.973-2.607-1.104-2.582-.095.017.023.774-.08.798C1 21.858.31 20.275.082 18.395c-.157-1.29-.058-2.401.046-3.532.178-1.922.526-3.35.828-4.48.051-.19.436-1.597.93-2.894C3.034 4.488 5.295.713 7.304.116z" />
        <path d="M9.707 15.707c0-.705.071-1.338.215-1.898.143-.56.347-1.005.613-1.337.265-.332.561-.498.89-.498.326 0 .5.197.52.59.02.396-.061.894-.245 1.494a14.1 14.1 0 0 1-.766 1.93 32.574 32.574 0 0 1-.982 1.897 10.143 10.143 0 0 1-.245-2.178zm2.146-7.715c1.308 0 2.239.446 2.79 1.337.308.498.782 1.486.644 3.298-.195 2.567-1.424 4.462-2.09 5.464a14.892 14.892 0 0 1-2.325 2.719c.08.234.29.741.797 1.15.65.525 1.366.537 1.605.538 1.005.005 1.723-.568 2.504-1.19.698-.558 1.124-1.085 1.625-1.712a17.715 17.715 0 0 0 1.598-2.376 9.474 9.474 0 0 1-.063-.055l2.685.642-.08.016a16.124 16.124 0 0 1-1.691 2.74l-.272.34c-.736.892-1.503 1.64-2.3 2.24-.57.43-1.287.962-2.36 1.369-.609.23-1.258.47-2.147.467a4.605 4.605 0 0 1-2.295-.574 4.63 4.63 0 0 1-1.63-1.542 6.21 6.21 0 0 1-.004.007l-1.462-2.551c-.535-1.131-.802-2.399-.802-3.803 0-1.452.214-2.81.644-4.075.429-1.265 1.042-2.323 1.84-3.174.796-.85 1.726-1.275 2.79-1.275z" />
        <path d="M20.698 12.71c-.287.933-.552 1.608-.798 2.022-.572-.622-.92-1.44-1.042-2.458-.122-1.016-.102-2.001.061-2.956.163-.953.45-1.762.859-2.426.408-.664.879-.933 1.41-.81.286.085.43.489.43 1.214 0 .727-.083 1.577-.246 2.551-.164.976-.389 1.93-.674 2.863zm.337-11.636c.837-.228 1.625-.218 2.36.03 1.39.458 2.085 1.93 2.085 4.419s-.899 5.766-2.698 9.831c.531-.29 1.074-.715 1.626-1.276a14.73 14.73 0 0 0 1.533-1.835c.411-.58.837-1.143 1.287-1.96.06-.107.3-.548.656-1.116.455-.727.64-.93.908-1.062.272-.134.769-.264 1.033-.034.123.108.29.383.01 1.372-.302 1.064-.854 1.788-1.718 3.049a20.321 20.321 0 0 1-2.084 2.582c-.736.768-1.483 1.4-2.239 1.898-.757.498-1.502.809-2.238.933l-.026.053-2.532-.626.017-.03a9.108 9.108 0 0 1-1.83-2.177l-.154-.266c-.114-.206-1.947-3.635-.81-7.438.529-1.767 1.607-3.32 1.41-3.454-.108-.074-.518.416-.565.373-.065-.058.559-1.093 1.577-1.96.475-.404 1.241-.993 2.392-1.306zM31.979 6.07c-.016.158-.057.35-.196.548-.062.089-.125.178-.253.255-.313.187-.706.116-.782.102a1.189 1.189 0 0 1-.535-.245c-.066-.056-.2-.18-.212-.333-.016-.21.21-.366.505-.578 1.003-.72 1.077-.856 1.229-.811.34.099.263.867.244 1.063" />
      </g>
    </svg>
  );
}

export default Logo;
