import React from "react";
import Head from "next/head";
import Logo from "./Logo";

function FlashBanner() {
  return (
    <>
      <Head>
        {/* eslint-disable-next-line */}
        <link
          href="https://fonts.googleapis.com/css2?family=Cairo:wght@600&display=swap"
          rel="stylesheet"
        />
      </Head>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      ._wuilt_free_banner {
        font-family: "Cairo", sans-serif !important;
        position: relative;
        z-index: 999999999;
        direction: rtl;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 4.063em;
        background-image: linear-gradient(to left, #00f490 0%, #6723a5 60%);
        box-shadow: 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.2);
      }
      
      ._wuilt_free_logo {
        margin: 0 0.625em 0 0.625em;
      }
      
      p._wuilt_free_intro {
        font-family: "Cairo", sans-serif !important;
        color: #ffffff;
        font-size: 0.625em;
        text-align: center;
        margin: 0;
      }
      
      ._wuilt_free_btn {
        width: 10em;
        height: 2.5em;
        border-radius: 1.688em;
        color: #ffffff;
        font-weight: bold;
        font-size: 0.75em;
        background: none;
        border: solid 0.063em #ffffff;
        margin: 0 0.625em 0 0.625em;
        padding-top: 5px;
        text-align: center;
        justify-content: center;
      }
      
      @media (min-width: 48em) {
        p._wuilt_free_intro {
          font-size: 0.75em;
        }
        ._wuilt_free_logo {
          width: 2em;
          margin: 0 1.563em 0 0;
        }
        ._wuilt_free_btn {
          width: 8.563em;
          height: 2.125em;
          margin: 0 0 0 1.5em;
        }
      }
      
      @media (min-width: 62em) {
        p._wuilt_free_intro {
          font-size: 0.875em;
        }
        ._wuilt_free_logo {
          margin: 0 3.125em 0 0;
        }
        ._wuilt_free_btn {
          margin: 0 0 0 3.062em;
          font-size: inherit;
        }
      }
      
        `,
        }}
      />

      <div className="_wuilt_free_banner">
        <div className="_wuilt_free_logo">
          <Logo />
        </div>
        <p className="_wuilt_free_intro">
          تم إنشاء هذا الموقع باستخدام منصة ويلت ﻹنشاء المواقع، قم بإنشاء موقع
          الويب الخاص بك مجانًا اليوم!
        </p>
        <a className="_wuilt_free_btn" href="https://app.wuilt.com/">
          ابدأ الآن
        </a>
      </div>
    </>
  );
}

export default FlashBanner;
